.scroll-to-top {
  z-index: 2;
  position: fixed !important;
  bottom: 2vh;
  background-color: black !important;
  stroke: #dcdcdc;
  opacity: 0.5;
  /* fill: transparent; */
  color: #dcdcdc !important;
  right: 5%;
}

.scroll-to-top:hover,
.Mui-focusVisible {
  transition: 0.3s;
  color: #397ba6;
  background-color: #dcdcdc;
  opacity: 1;
}

.scroll-to-top:focus {
  outline: none;
  box-shadow: none;
}