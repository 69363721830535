

.menuItemCart {
    cursor: default !important;
    width: 100% !important;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 6px;
}

.menuOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 10px 0px 5px 0px;
}

.menuOptions:hover {
    text-decoration: none;
}

.totalCart {
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: right;
}

.dropDownCart_mobile {
    padding: 10px 30px;
}

.totalCart_mobile {
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: left;
}

.rowCartProduct {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.colCartProduct1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    text-align: left;
    margin-right: 20px;
}

.colCartProduct1_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    text-align: left;
    margin-right: 20px;
}

.colCartProduct2 {
    display: flex;
    flex-direction: column;
    width: 80%;
    font-family: "Montserrat", sans-serif;
    text-align: right;
    justify-content: space-between;
}

.colCartProduct2_mobile {
    display: flex;
    flex-direction: column;
    width: 10%;
    font-family: "Montserrat", sans-serif;
    text-align: right;
    align-items: flex-end;
}

.dropdown-profile,
.dropdown-logout {
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    word-break: normal;
    text-overflow: ellipsis;
    overflow: hidden;
}

.titleCartProduct {
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    word-break: normal;
}

.rowDetailedCart {
    display: block;
    flex-direction: row;
    font-size: 16px;
    justify-content: space-between;
    width: 100%;
}

.cartPriceProduct {
    width: 40%;
}

.cartPriceProduct_mobile {
    width: 40%;
    margin-right: 30px;
}

.quantityPriceProduct {
    width: 60%;
}

.quantitySizeProduct {
    width: 60%;
    text-transform: uppercase;
    font-weight: bold;
}

.addCart,
.subsCart,
.dropdown-profile,
.dropdown-logout {
    cursor: pointer;
}

.addCart {
    margin-bottom: 10px;
}

.dropdown-profile:hover,
.dropdown-logout:hover {
    background-color: #e9e9e9;
}

.addCart:hover,
.subsCart:hover {
    svg{
        background-color: #e9e9e9;
    }
}

.buttonCheckoutCart {
    background-color: #1d1d1b;
    font-size: 20px;
    font-family: "Bebas Neue";
    color: #fae6c5;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 1rem;
    padding: 3px;
}

.separadorCartDrop {
    width: 100%;
    margin: 0 auto;
}

.button-link__checkout-mobile {
    width: 100%;
    text-align: center;
}

.button-link__checkout-mobile>button {
    width: 100%;
}