.image-carousel-root {
    flex-grow: 1;
    margin: 0 auto;
    margin-bottom: 24px;
}


.image-carousel-root img{
    pointer-events: none;
    max-width: 460px;
    width: 100%;
}

@media screen and (max-width: 500px) {
    .image-carousel-root{
        margin: 24px;
    }
  }