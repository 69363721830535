/* #FAE6C5 Crema
#F2C571 Naranja
#EB5E3E Rojo
#1D1D1B Negro
#FFED00 Amarillo */

:root {
    --primary: #f2c571;
    --warning: #ffed00;
    --danger: #eb5e3e;
    --success: #75fa83;
    --white: #fdfdfd;
    --naranja: #f2c571;
    --rojo: #eb5e3e;
    --negro: #1d1d1b;
    --amarillo: #ffed00;
}

.boton {
    font-family: "Bebas Neue", sans-serif;
    width: fit-content;
    white-space: nowrap;
    font-weight: 400;
    cursor: pointer;
    color: var(--color-cream);
    outline: none;
    transition: transform 0.3s ease;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    position: relative;
}

.boton {
    font-family: "Bebas Neue", sans-serif;
    width: fit-content;
    white-space: nowrap;
    font-weight: 400;
    cursor: pointer;
    color: var(--color-cream);
    outline: none;
    transition: transform 0.3s ease;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
}

.button-disabled {
    opacity: 0.6;
    cursor: unset;
}

.button-disabled:hover {
    pointer-events: none;
    cursor: unset;
    transform: translateY(0px) !important;
}

.button-disabled svg:hover {
    pointer-events: none;
    cursor: unset;
    transform: translateY(0px) !important;
}

.button-disabled:hover {
    transform: translateY(0px) !important;
    cursor: unset;
}

.boton:focus {
    outline: none;
}

.boton:hover:not([disabled]) {
    transform: translateY(-2px);
}


/* Colores y estilos del botón */

.boton--primary--solid {
    background-color: var(--negro);
    color: var(--color-cream);
    border: none;
}

.boton--primary--outline {
    background-color: transparent;
    border: 2px solid var(--negro);
    color: var(--negro);
}

.boton--primary--disabled {
    background-color: var(--negro);
    color: var(--color-cream);
    border: none;
    opacity: 0.6;
    pointer-events: none;
}

.boton--orange--solid {
    background-color: var(--negro);
    color: var(--color-cream);
    border: none;
}

.bg-red:hover:not([disabled]) {
    background-color: var(--rojo);
    color: var(--negro);
}

.bg-red.active:not([disabled]) {
    background-color: var(--rojo);
    color: var(--color-cream);
}

.bg-cream:hover:not([disabled]) {
    background-color: var(--color-cream);
    color: var(--negro);
}

.bg-cream.active {
    background-color: var(--color-cream);
    color: var(--negro);
}

.bg-orange:hover:not([disabled]) {
    background-color: var(--naranja);
    color: var(--negro);
}

.bg-orange:active {
    background-color: var(--naranja);
    color: var(--negro);
}

.boton--back-orange--solid {
    background-color: var(--rojo);
    color: var(--color-cream);
    border: none;
}

.boton--medium {
    padding: 16px 20px 12px 20px;
    font-size: 28px;
    height: 56px;
}

.boton--small {
    padding: 5px 10px;
    font-size: 20px;
    height: 30px;
}

.boton--big {
    padding: 10px 40px 5px 40px;
    font-size: 36px;
    height: 51px;
}

.boton--xxl {
    padding: 10px 40px 5px 40px;
    font-size: 40px;
    height: 56px;
}

.boton--megaxxl {
    padding: 8px 40px 5px 40px;
    font-size: 40px;
    width: 100%;
    height: 53px;
}

.boton--megaxxl>svg {
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 320px) {
    .boton {
        font-size: 22px;
    }
}