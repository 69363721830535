.payment-root {
    display: flex;
    align-items: center;
    margin: 20px 0px;
}

.payment-body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100vw;
    padding: 20px;
}

.payment-body>form>button {
    margin-top: 15px;
    width: 100%;
}

#payment-form {
    width: 30vw;
    height: fit-content;
    align-self: center;
    border-radius: 7px;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#payment-form>button {
    margin-bottom: 20px;
}

#payment-form>button {
    margin-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
}

.result-message {
    line-height: 22px;
    font-size: 16px;
}

.result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
}

.hidden {
    display: none;
}

#card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
}

#card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}

#payment-request-button {
    margin-bottom: 32px;
}


/* Buttons and links */

.payment-button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

.payment-button:hover {
    filter: contrast(115%);
}

.payment-button:disabled {
    opacity: 0.5;
    cursor: default;
}

@media only screen and (max-width: 600px) {
    #payment-form {
        width: 80vw;
    }
}