/* COLOURS */
:root {
    --color-cream: #FAE6C5;
    --color-naranja: #F2C571;
    --color-rojo: #EB5E3E;
    --color-negro: #1D1D1B;
    --color-gris: #485461;
    --color-amarillo: #FFED00;
    --color-gris-claro: #A9A9A9;
  }

  #root{
    height: 100vh;
  }
  
  html {
      background-color: #1D1D1B;
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Avoid error cover image on dev server */
iframe#webpack-dev-server-client-overlay {
    display: none;
  }

  .pswp__img, .pswp__zoom-wrap {
    width: 100% !important;
    height: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
}