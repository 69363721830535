.agendaLink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    font-family: 'Bebas Neue';
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    margin: 1rem 0 2rem 0;
}

.agendaLink a {
    text-decoration: none;
    color: #1D1D1B;
}